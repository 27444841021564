html, body {
  font-family: 'Montserrat', Arial !important;
}
.App {
  text-align: left;
}

.nav-link {
  color: #fff !important;
}
.btn-outline-primary {
  border-radius: 0px !important;
  padding: 10px 20px !important;
  letter-spacing: 3px;
  color: #fff !important;
  border-color: #fff !important;
}
.btn-close {
  background: transparent url(./img/close.png) !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  opacity: 1 !important;
}
.modal-content {
  background: transparent !important;
  border: 0 !important;
}
.modal-service-header { 
  background: transparent !important;
  border-bottom: 0 !important;
}
.modal-service-body { 
  background-color: #f8f9fc;
  /*background-image: url(./img/social.jpg);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;*/
  padding: 20px !important;
  border-radius: 25px;
}
.modal-service-body-text { 
  padding: 10px 10px 50px 10px !important;
  border-radius: 25px;
}

.p-30 {
  padding: 30px;
}
.c-white {
  color: #fff !important;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.nav-bar-custom {
  background-color: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.23);
  padding: 10px 0 !important;
}
.nav-bar-custom-start {
  background-color: transparent !important;
  padding: 10px 0 !important;
}
.brand-image {
  width: 120px;
  cursor: pointer;
}
.brand-image-start {
  width: 180px;
  cursor: pointer;
}
.banner-bg {
  background-image: url(./img/terminal.jpg);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner-title {
  font-size: 50px;
  font-weight: 400;
  font-style: italic;
  color: #fff;
  text-align: right;
  line-height: 60px;
  padding: 200px 0 0px 0;
}
.banner-title-700 {
  font-weight: 700;
  text-transform: uppercase;
}
.brands-container {
  position: relative;
  background-color: transparent;
  width: 100% !important;
  bottom: -120px;
}
.brands {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px !important;
  max-width: 700px !important;
  box-shadow: 7px 10px 31px -15px rgba(0,0,0,0.35);
  -webkit-box-shadow: 7px 10px 31px -15px rgba(0,0,0,0.35);
  -moz-box-shadow: 7px 10px 31px -15px rgba(0,0,0,0.35);
}
.brands-img {
  width: 100px;
}
.section-a {
  padding: 200px 0 100px 0 !important;
  background-color: #f8f8f8;
}
.section-b {
  padding: 100px 0 100px 0 !important;
  width: 100%;
  background-color: #fff;
}
.section-c {
  padding: 100px 0 100px 0 !important;
  width: 100%;
  background-color: #07244c;
}
.section-d {
  padding: 100px 0 100px 0 !important;
  width: 100%;
  background-color: #fff;
  background-image: url(./img/contact-bg.jpg);
  background-size: cover;
  background-position: top;
}
.secction-titles {
  font-size: 40px;
  font-weight: 700;
  color: #07244c;
}
.us-img {
  width: 100%;
  padding: 30px;
}
.grl-title {
  font-size: 22px;
  font-weight: 600;
  color: rgb(51, 51, 51);
}
.grl-text {
  font-size: 18px;
  color: rgb(51, 51, 51);
}
.p-item-padding {
  padding-top: 12px;
  padding-bottom: 12px;
}
.p-card {
  border-radius: 15px;
  border: 1px solid #c3c3c3;
  padding: 20px;
}
.t-card {
  font-size: 20px;
  font-weight: 600;
}
.b-card {
  font-size: 15px;
  color: #7e7d7d;
  font-style: italic;
}
.img-card {
  width: 80%;
  padding: 5px;
}
.contact-info {
  font-size: 17px;
}
.form-container {
  padding-left: 70px !important;
  padding-right: 70px !important;
}
.label-custom {
  color: #fff;
}
.footer-icons {
  color: #b9b5b5; 
  font-size: 20px; 
  cursor: pointer;
}
.footer {
  background-color: #000;
  font-size: 13px;
  font-weight: 400;
  padding: 50px 0;
  color: #b9b5b5;
  text-align: center;
}
.footer-title {
  color: #fff;
  font-weight: 800;
  cursor: pointer;
}
.pre-footer {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 150px;
}
.footer-logo {
  width: 100px;
}

fieldset.scheduler-border {
  border: 2px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  box-shadow:  0px 0px 0px 0px #000;
  color: #fff;
  position: relative;
}
legend.scheduler-border {
    font-size: 2em !important;
    font-weight: 600 !important;
    text-align: left !important;
    width:auto;
    padding:0 15px;
    border-bottom:none;
    color: #fff;
    top: -30px;
    position: absolute;
    background-color: #07244c;
}

@media (max-width: 460px) {
  .section-d, .section-c, .section-b{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .banner-title {
    font-size: 30px;
    text-align: center;
    line-height: 35px;
    padding: 200px 0 0px 0;
  }
  .secction-titles {
    font-size: 30px;
  }
  .grl-text{
    text-align: center;
  }
  .form-container {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .navbar-collapse {
    background-color: #fff !important;
  }
  .nav-link-custom {
    padding-left: 15px !important;
  }
}
